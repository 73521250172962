<template>
  <div>
    <div style="text-transform: uppercase">
      <h3 v-if="this.$route.query.searchCall == 'Snare'">Snares Removed</h3>
      <h3 v-if="this.$route.query.searchCall == 'Trap'">Traps Removed</h3>
      <h3 v-if="this.$route.query.searchCall == 'Rescued'">Animal Rescued</h3>
         <h3 v-if="this.$route.query.searchCall == 'HuntingTool'">Hunting Tools Recovered</h3>
    </div>
    <div id="commonMap"></div>
    <div>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="blue"
        spinner="mini-spinner"
      />
      <ServerError v-if="ServerError" />
    </div>
  </div>
</template>
<script
    src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBTkuPaji75FkC_WBegL4cupA9_HJWNAuE&libraries=visualization,drawing">
</script>
<script>
import axios from "axios";
export default {
  //   props: ["snareData", "Trails"],

  data() {
    return {

        main:"",
      snareData: [],
      defaultLAt: 0,
      defaultLON: 0,
      icons: "",
      totalLength: 0,
      page: 1,
      appLoading: false,
      ServerError: false,
      user: [],
      records: [],
      gmap: null,
      pagelength: 0,
      allData: [],
      //map data
      latt: null,
      lonn: null,
      CORD: {
        lat: "",
        lng: "",
      },
      flightPlanCoordinates: [],
      flight: [],
    };
  },
  watch: {},
  mounted() {
    this.getMap();
    this.getMapData();
  },
  methods: {
    getMapData() {
      this.appLoading = true;
      axios({
        url: "/view/location/details",
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          type: this.$route.query.searchCall,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.snareData = response.data.data;
            this.snareList();
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    snareList() {
      console.log("im working");
      console.log("snareLength", this.snareData.length);
      for (let i = 0; i < this.snareData.length; i++) {
        console.log("this.snareData", this.snareData);
        console.log(
          "this.$route.query.searchCall=",
          this.$route.query.searchCall
        );
        if (this.$route.query.searchCall != "Rescued") {
          this.latt = this.snareData[i].snareLocation[1];
          this.lonn = this.snareData[i].snareLocation[0];
        } else {
          this.latt = this.snareData[i].animalLocation[1];
          this.lonn = this.snareData[i].animalLocation[0];
        }

        let l = i + 1;
        var ic1 = "../../assets/images/markers/redMarker.png";
        var ic2 = "../../assets/images/ymarker.png";
        var icMain = "";
        if (this.$route.query.searchCall != "Rescued") {
          this.main =ic2;
        } else {
          this.main =ic1;
        }
       if (this.$route.query.searchCall == "Snare") {
        this.icons = {
          url: require( "../../assets/images/ymarker.png"),
          size: new google.maps.Size(100, 60),
          scaledSize: new google.maps.Size(20, 30),
          anchor: new google.maps.Point(0, 50),
        };
       }
       else{
        this.icons = {
          url: require( "../../assets/images/markers/redMarker.png"),
          size: new google.maps.Size(100, 60),
          scaledSize: new google.maps.Size(20, 30),
          anchor: new google.maps.Point(0, 50),
        };

       }
        this.marker = new google.maps.Marker({
          position: { lat: this.latt, lng: this.lonn },
          map: this.gmap,
          icon: this.icons,
        });

        this.marker.addListener("click", (eve) => {
          infowindow.open(this.map3, this.marker);
          infowindow.setPosition(eve.latLng);
        });
      }

      //   for (let A = 0; A < this.Trails.length; A++) {
      //     this.flightPlanCoordinates = [];
      //     this.flight = [];
      //     //  this.Trails=[];

      //     for (let i = 0; i < this.Trails[A].length; i++) {
      //       this.CORD.lat = this.Trails[A][i].location[1];
      //       this.CORD.lng = this.Trails[A][i].location[0];
      //       this.flight.push(JSON.parse(JSON.stringify(this.CORD)));
      //       console.log("this.CORD=", this.CORD);
      //       this.CORD.lat = "";
      //       this.CORD.lon = "";
      //     }
      //     console.log("this.flight=", this.flight);
      //     this.flightPlanCoordinates = this.flight;
      //     const flightPath = new google.maps.Polyline({
      //       path: this.flightPlanCoordinates,
      //       geodesic: true,
      //       strokeColor: "#5282e3",
      //       strokeOpacity: 1.0,
      //       strokeWeight: 5,
      //     });
      //     flightPath.setMap(this.gmap);
      //   }
    },
    ChangeFormateDate(oldDate) {
      if (oldDate) {
        var newdateFormat = oldDate.split("T");
        var splitDate = newdateFormat[0].split("-");
        if (splitDate.count == 0) {
          return null;
        }
        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2];
        return month + "/" + day + "/" + year;
      }
    },

    getMap() {
      this.gmap = new google.maps.Map(document.getElementById("commonMap"), {
        center: { lat: 22.5260093, lng: 76.8144186 },
        mapTypeId: "satellite",
        zoom: 4.6,
      });

      if (this.snareData.length > 0) {
        this.snareList();
      }
    },
  },
};
</script>

<style scoped>
#commonMap {
  height: 550px;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
</style>
